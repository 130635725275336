/* @font-face {
  font-family: "o_bold";
  src: url("../Fonts/Poppins-Bold.ttf") format("ttf"),
    url("../Fonts/Poppins-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "o_light";
  src: url("../Fonts/Poppins-Light.ttf") format("ttf"),
    url("../Fonts/Poppins-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "o_medium";
  src: url("../Fonts/Poppins-Medium.ttf") format("ttf"),
    url("../Fonts/Poppins-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "o_semibold";
  src: url("../Fonts/Poppins-SemiBold.ttf") format("ttf"),
    url("../Fonts/Poppins-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "o_regular";
  src: url("../Fonts/Poppins-Regular.ttf") format("ttf"),
    url("../Fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "b_bold";
  src: url("../Fonts/BaiJamjuree-Bold.woff2") format("woff2"),
    url("../Fonts/BaiJamjuree-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "b_medium";
  src: url("../Fonts/BaiJamjuree-Medium.woff2") format("woff2"),
    url("../Fonts/BaiJamjuree-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "b_light";
  src: url("../Fonts/BaiJamjuree-Light.woff2") format("woff2"),
    url("../Fonts/BaiJamjuree-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "b_regular";
  src: url("../Fonts/BaiJamjuree-Regular.woff2") format("woff2"),
    url("../Fonts/BaiJamjuree-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "b_semibold";
  src: url("../Fonts/BaiJamjuree-SemiBold.woff2") format("woff2"),
    url("../Fonts/BaiJamjuree-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
} */

/* @import url('https://fonts.cdnfonts.com/css/flama-trial'); */



:root {

   /* --bold:"o_bold";
   --semibold:"o_semibold";
  --medium:"o_medium";
  --light:"o_light";
  --regular:"o_regular";  */
  --bg:#ee8f0a;
}

* {
  margin: 0;
  padding: 0;
  /* word-break: break-word; */
  box-sizing: border-box;
  font-family: var(--regular);
}
h1,h2,h3,h4,h5,h6 {
  padding: 0;
  margin: 0;
}
.wrapper {
  max-width: 2000px;
  padding: 0 40px;
  margin: auto;
}
.home_wrapper {
  /* max-width: 1360px; */
  max-width: 2000px;
  margin: auto;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  cursor: pointer;
}
img{
  max-width: 100%;
  display: block;
}

.dynamic_section_home {
  margin: 50px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 100px;
}
ul {
  list-style: none;
}

.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item:hover::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu:hover::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-active::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-active::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-item-open::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-open::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-open::after {
  border-bottom-color:var(--bg)
}

/* .swiper-button-next:after, .swiper-button-prev:after {
  color: var(--bg);
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background: var(--bg);
} */

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected::after, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected::after {
  border-bottom-color: var(--bg);
}
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected, .ant-menu-light>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected {
  color: var(--bg);
}

.my_profile_section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 60px 0;
}
.my_profile_section .my_profile_left {
  width: 25%;
  display: inline-block;
  padding: 20px 20px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  min-height: 300px;
}
.my_profile_section .my_profile_right {
  width: 72%;
  display: inline-block;
}
.my_profile_section .my_profile_left ul {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.my_profile_section .my_profile_left ul li a {
  color: #000;
  font-family: var(--semibold);
  font-size: 15px;
}

.Payment {
 
  margin: 15px 0;
  padding: 30px 30px;
  border-radius: 10px;
  background: #FAFAFA;
  filter: drop-shadow(5px 5px 10px rgba(180, 180, 180, 0.3));
  

}

.Info_box .p {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  place-content: initial;
  line-height: 0;
  width: 100%;
  flex: 0 0 40% ;
  padding: 0 10px;
  

}

.Section_Wrapper {
  padding: 0 40px 0 40px;
  background-color: red;
}


