/* src/assets/fonts/fonts.css */

.inter-normal-400 {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .inter-bold-700 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .montserrat-normal-400 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .montserrat-bold-700 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  